import { Modal } from 'gantri-components';
import { useEffect, useState } from 'react';
import { jobsApi } from '../../../../api';
import {
  InventoryRequest,
  Machine as JobMachine,
} from '../../../../api/jobs/jobs.types';
import { OpenIssue } from '../../../../api/machines/machines.types';
import { machineTypes } from '../../../../constants/machines';
import {
  jobStatuses,
  jobSteps,
  jobTypeOptions,
} from '../../../../constants/options';
import {
  MachineRepairIssueType,
  MachineRepairType,
} from '../../../../constants/options/options.types';
import { useNotification } from '../../../../hooks/useNotification';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { Content } from './components/content';
import { Footer } from './components/footer';
import {
  RepairJobModalProps,
  RepairJobModalStep,
} from './repair-job-modal.types';
import { useSpinner } from '../../../../hooks';
import { JobDetailsPanelLarge } from '../../../jobs/components/modals/common/job-details-panel-large';
import { FullScreenModalHeader } from '../../../jobs/components/modals/common/full-screen-modal-header';
import { getMachine } from './helpers/get-machine';
import { getJobInventoryRequests } from './helpers/get-job-inventory-requests';
import { JobDetails } from '../../../../api/jobs/routes/get-job-details/get-job-details.types';

export const RepairJobModal = (props: RepairJobModalProps) => {
  const {
    job: startingJobData,
    machine: startingMachineData,
    onClose,
    onJobUpdated,
  } = props;

  const machine = getMachine({ startingJobData, startingMachineData });

  const jobInventoryRequests = getJobInventoryRequests({
    startingJobData,
  });

  const [repairType, setRepairType] = useState<MachineRepairType>({
    description: startingJobData?.description,
    duration: startingJobData?.duration,
    type: startingJobData?.description as MachineRepairIssueType,
  });

  const defaultMachine: Partial<JobMachine> = {
    id: machine?.id,
    name: machine?.name,
    type: machine?.type,
  };

  const defaultJob: Partial<JobDetails> = {
    machine: defaultMachine as JobMachine,
    step: jobSteps.technician,
    type: jobTypeOptions.repair,
  };

  const [job, setJob] = useState<JobDetails>(
    startingJobData || (defaultJob as JobDetails),
  );

  const isJobInProgress = job?.status === jobStatuses.inProgress;
  const isMachineWithComponentLifespans =
    machineTypes.creality === machine.type;

  const defaultStep: RepairJobModalStep = isJobInProgress
    ? 'CONFIRM_USED_INVENTORY'
    : 'NEW_REPAIR';

  const [step, setStep] = useState<RepairJobModalStep>(defaultStep);
  const [inventoryRequests, setInventoryRequests] =
    useState<InventoryRequest[]>(jobInventoryRequests);

  const [notes, setNotes] = useState<string>(job?.notes || '');
  const [selectedIssues, setSelectedIssues] = useState<OpenIssue[]>(
    startingJobData?.machineIssues || [],
  );

  const [updateOnClose, setUpdateOnClose] = useState<boolean>(isJobInProgress);
  const { notifyAxiosError } = useNotification();
  const { onInterceptRequest } = useSpinner();

  const handleUpdateJob = async () => {
    await onInterceptRequest(async () => {
      if (updateOnClose) {
        const issueIds = selectedIssues.map(({ id }) => {
          return id;
        });

        try {
          await jobsApi.updateJob({
            inventoryRequests,
            issues: issueIds,
            jobId: job?.id,
            notes,
          });
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to update job.',
          });
        }
      }
    });
  };

  const handleCloseModal = async () => {
    await handleUpdateJob();

    onClose();

    if (step !== 'NEW_REPAIR') {
      await onJobUpdated();
    }
  };

  useEffect(() => {
    if (!!startingJobData?.notes || !repairType?.description || !job?.notes) {
      return;
    }

    setUpdateOnClose(true);
  }, [inventoryRequests, job?.notes]);

  return (
    <Modal
      closeable={false}
      dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
      detailsPanel={
        <JobDetailsPanelLarge
          job={{
            ...job,
            machineIssues: selectedIssues,
            notes: repairType ? notes : job.notes,
          }}
          repairType={repairType}
          sectionsToShow={[
            'machine type',
            'machine details',
            'machine',
            'machine issues',
            'inventories',
            'notes',
            'repair type',
          ]}
        />
      }
      detailsPanelWidth="1fr"
      footer={
        <Footer
          handleCloseModal={handleCloseModal}
          inventoryRequests={inventoryRequests}
          isMachineWithComponentLifespans={isMachineWithComponentLifespans}
          job={job}
          notes={notes}
          repairType={repairType}
          selectedIssues={selectedIssues}
          setJob={setJob}
          setStep={setStep}
          setUpdateOnClose={setUpdateOnClose}
          step={step}
        />
      }
      header={
        <FullScreenModalHeader handleCloseModal={handleCloseModal} job={job} />
      }
      isFullScreen
    >
      <Content
        inventoryRequests={inventoryRequests}
        isJobInProgress={isJobInProgress}
        notes={notes}
        openIssues={machine?.openIssues.filter(({ jobId }) => {
          return !jobId || isJobInProgress;
        })}
        repairType={repairType}
        selectedIssues={selectedIssues}
        setInventoryRequests={setInventoryRequests}
        setNotes={setNotes}
        setRepairType={setRepairType}
        setSelectedIssues={setSelectedIssues}
        setUpdateOnClose={setUpdateOnClose}
        step={step}
        onClose={handleUpdateJob}
      />
    </Modal>
  );
};
