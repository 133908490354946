import { Button } from 'gantri-components';
import { useToggle } from 'react-use';
import { jobsApi } from '../../../../../../../../api';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../constants/options';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../../../../../../jobs/components/modals/modals.constants';
import { NewRepairFooterProps } from './new-repair-footer.types';

export const NewRepairFooter = (props: NewRepairFooterProps) => {
  const {
    isMachineWithComponentLifespans,
    machine,
    notes,
    repairType,
    selectedIssues,
    setJob,
    setStep,
    setUpdateOnClose,
  } = props;

  const [processing, toggleProcessing] = useToggle(false);
  const { notifyAxiosError } = useNotification();

  const handleCreateNewRepairJob = async () => {
    try {
      toggleProcessing();

      const issueIds = selectedIssues.map(({ id }) => {
        return id;
      });

      const { data } = await jobsApi.createRepairJob({
        description: repairType.type,
        duration: repairType.duration,
        instruction: {},
        inventoryRequests: [],
        issues: issueIds,
        itemId: null,
        machineId: machine?.id,
        machineType: machine?.type,
        notes,
        partId: null,
        step: jobSteps.technician,
        stockId: null,
        type: jobTypeOptions.repair,
      });

      setJob(data.job);

      setStep('CONFIRM_USED_INVENTORY');
      setUpdateOnClose(true);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to create repair job.',
      });
    } finally {
      toggleProcessing();
    }
  };

  return (
    <Button
      disabled={!repairType || !selectedIssues.length}
      processing={processing}
      size={fullScreenModalFooterButtonSize}
      text="Start Repair"
      onClick={handleCreateNewRepairJob}
    />
  );
};
