import { Conditional, Dropdown, Stack, Typography } from 'gantri-components';
import { machineRepairTypes } from '../../../../../../../../constants/options';
import { MachineRepairType } from '../../../../../../../../constants/options/options.types';
import { ModalContentHeading } from '../../../../../../../jobs/components/modals/common/modal-content-heading';
import { RepairDetails } from './components/repair-details';
import { NewRepairContentProps } from './new-repair-content.types';

export const NewRepairContent = (props: NewRepairContentProps) => {
  const {
    notes,
    onClose,
    openIssues,
    repairType,
    selectedIssues,
    setNotes,
    setRepairType,
    setSelectedIssues,
  } = props;

  return (
    <Stack gap="3x">
      <ModalContentHeading
        subTitleText="Select repair type and confirm you want to start a new repair."
        titleText="Create New Repair"
      />
      <Stack gap="x">
        <Typography text="Repair Type" variant="h5" />
        <Dropdown
          idProperty="type"
          items={machineRepairTypes}
          labelProperty="type"
          placeholder="Select a repair type"
          value={repairType?.type}
          onSelect={(item: MachineRepairType) => {
            setRepairType(item);
            setNotes(item.description);
          }}
        />
      </Stack>
      <Conditional condition={!!repairType?.type}>
        <RepairDetails
          notes={notes}
          openIssues={openIssues}
          repairType={repairType}
          selectedIssues={selectedIssues}
          setNotes={setNotes}
          setSelectedIssues={setSelectedIssues}
          onClose={onClose}
        />
      </Conditional>
    </Stack>
  );
};
