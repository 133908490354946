import { useFormikContext } from 'formik';
import {
  Conditional,
  Dropdown,
  FormikInput,
  Grid,
  Icon,
  Line,
  SearchField,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { AddJobFormValues } from '../../../add-job-modal.types';
import { addJobModalAtoms } from '../../../add-job-modal.atoms';
import { AssignedToField } from '../../../../../../../../components/common/assigned-to-field';
import { Label } from '../../../../../../../../components/label';
import { useFetchConfigurations } from '../../../../../../../../api/configurations';
import { configurationNames } from '../../../../../../../../api/configurations/routes/fetch-configurations/fetch-configurations.types';
import { nonProductJobTypes } from '../../../../../../../../constants/options';
import { getDefaultStepForNonProductJobType } from '../../../helpers/get-default-step-for-non-product-job-type';

export const AddJobFormContent = () => {
  const { setFormikState, values } = useFormikContext<AddJobFormValues>();
  const { assignedTo, step, type } = values;

  const users = useRecoilValue(addJobModalAtoms.users);

  const { data } = useFetchConfigurations({
    fetchArgs: {
      names: [configurationNames.jobStepsMap],
    },
  });

  const jobStepsMap = data?.[configurationNames.jobStepsMap];
  const jobStepsForType = jobStepsMap?.[type] || [];

  const stepOptions = useMemo(() => {
    return jobStepsForType.map((step) => {
      return {
        label: step,
        value: step,
      };
    });
  }, [jobStepsForType]);

  const typeOptions = useMemo(() => {
    // ? Repair is considered a non-product job type, but it should
    // ? only be created via the create job repair modal
    const { repair: _repair, ...rest } = nonProductJobTypes;

    return Object.values(rest).map((type) => {
      return { label: type, value: type };
    });
  }, []);

  const theme = useTheme();

  const onTypeSelect = (typeItem: typeof typeOptions[number]) => {
    const type = typeItem?.value;
    const jobStepsForType = jobStepsMap?.[type] || [];

    const assignedStepIsAvailable = jobStepsForType.some((availableStep) => {
      return availableStep === step;
    });

    const defaultStepForType = getDefaultStepForNonProductJobType(type);

    let updatedStep = step;

    if (jobStepsForType.length === 1) {
      const [firstStep] = jobStepsForType;

      updatedStep = firstStep;
    } else if (defaultStepForType) {
      updatedStep = defaultStepForType;
    } else if (step && !assignedStepIsAvailable) {
      updatedStep = undefined;
    }

    setFormikState((prevState) => {
      return {
        ...prevState,
        values: {
          ...values,
          step: updatedStep,
          type,
        },
      };
    });
  };

  return (
    <Stack gap="4x">
      <Stack gap="2x">
        <Typography text="Select job and assign" variant="h5" />
        <FormikInput
          Field={
            <SearchField
              items={typeOptions}
              placeholder="Select type"
              onSelect={onTypeSelect}
            />
          }
          labelText="Type"
          name="type"
          required
        />
        <FormikInput
          autoFocus
          Field={
            <Dropdown
              disabled={!type}
              items={stepOptions}
              placeholder="Select step"
            />
          }
          fieldVariant="standard"
          labelText="Step"
          name="step"
          required
        />

        <Stack gap=".5x">
          <Label text="Assigned to" />
          <FormikInput
            Field={
              <AssignedToField
                enableSelectedItemsList
                selectedItemsList={assignedTo}
                users={users}
              />
            }
            fieldVariant="standard"
            name="assignedTo"
            required
          />
        </Stack>
        <Conditional condition={assignedTo.length > 1}>
          <Grid
            alignItems="baseline"
            backgroundColor={theme.colors.surfaces.monochrome.t1Alt}
            columns="max-content 1fr"
            gap="x"
            padding="x"
          >
            <Icon name="people:people" top="2px" />
            <Typography text="Note: The system will create a job for each of the assigned operators." />
          </Grid>
        </Conditional>
      </Stack>

      <Line />

      <Stack gap="2x">
        <Typography text="Configure details" variant="h5" />
        <FormikInput
          debounce={300}
          labelText="Duration (min)"
          name="duration"
          placeholder="Input duration"
          required
          rightIcon={<Typography color="t2" text="m" />}
          type="number"
        />
        <FormikInput
          debounce={300}
          Field={<TextArea minRows={4} placeholder="Enter instructions" />}
          labelText="Instruction"
          name="instruction"
        />
      </Stack>
    </Stack>
  );
};
